class Tree{
	const planet;
	const obj;
	const scaleProps;
	const scaleStarts;
	const treeData;
	
	

	constructor(_treeData,shader,type,_stage,initEnd){
		

		const kiMap1=new THREE.TextureLoader().load("img/ki1.jpg");
		const kiMap2=new THREE.TextureLoader().load("img/ki2.jpg");
		const kiMap3=new THREE.TextureLoader().load("img/ki3.jpg");
		const delays=[];
		const RANDOM_NUMS=[0.25,0.28,0.99,0.83,0.47,0.68,0.95,0.41,0.69,0.82,0.31,0.91,0.25,0.13,0.19,0.72,0.93,0.39,0.97,0.83,0.36,0.56,0.73,0.12,0.96,0.57,0.39,0.84,0.12,0.18,0.58,0.76,0.04,0.42,0.56,0.1,0.45,0.23,0.26,0.03,0.22,0.19,0.29,0.04,0.5,0.82,0.81,0.86,0.77,0.37,0.96,0.33,0.62,0.31,0.66,0.8,0.8,0.94,0.24,0.09,0.5,0.66,0.07,0.31,0.2,0.73,0.8,0.28,0.05,0.86,0.23,0.66,0.17,0.13,0.59,0.62,0.11,0.97,0.05,0.43,0.05,0.93,0.5,0.05,0.09,0.27,0.18,0.78,0.06,0.11,0.45,0.97,0.43,0.79,0.1,0.44,0.9,0.17,0.88,0.94];



		treeData=_treeData;

		let fbxLoader=new THREE.FBXLoader();
		fbxLoader.addParseEvent(loadFbx);
		fbxLoader.load("fbx/kiSet1_bone.fbx.zip",1,function(){});

		let TreeObj3D=new THREE.Object3D();
		TreeObj3D.name="TreeObj3D";
		//TreeObj3D.scale.set(0.3,0.3,0.3);
		_stage.add(TreeObj3D);

		


		function loadFbx(_obj){

			//console.log("_obj=")
			//console.log(_obj)

			var meshEnd=false;
			let meshObj3D;
			let mikiTexture
			let treeCount=0;
			let partsCount;
			let partsCountMax;
			let partsName;

			let originalTrees=[];
			let originalMaterials=[];

			let objArray={};
			

			for(var m=0;m<_obj.children.length;m++){

				if(_obj.children[m].type=="SkinnedMesh"||_obj.children[m].type=="Mesh"){
					let meshs=analizeLoadFbx(_obj.children[m]);
					let treeMesh=getOneGeometryMesh(meshs);
					treeMesh.geometry.computeBoundingBox();

					//TreeObj3D.add(treeMesh);
					originalTrees[treeMesh.name]=treeMesh;
					//console.log(meshs)
					//console.log(treeMesh)
				}

			}

			//console.log("originalTrees")
			//console.log(originalTrees);

			//let haMateriaColor=new THREE.Color(0.6,0.9,0.4);
			//let haMateriaColor=new THREE.Color(0.7,0.8,0.6);//茶っぽい
			let haMateriaColor=new THREE.Color(0.5,0.8,0.6);
			let haMateriaColor1=new THREE.Color(0.2,0.7,0.5);
			//haMateriaColor1=new THREE.Color(1,0,0);
			//let haMateriaColor2=new THREE.Color(0.0,0.7,0.3);
			let haMateriaColor2=new THREE.Color(0.2,0.5,0.4);
			let haMateriaColor3=new THREE.Color(0.15,0.35,0.135);


			let PI=String(Math.PI)+String(Math.sqrt(2))+String(Math.sqrt(3))+String(Math.E)+String(Math.LN2);
			PI=PI.replace(/\./g,"0");
			
			let PIL=PI.length;

			let yVector=new THREE.Vector3(0,EARTH.circleR/0.3,0)
				

			//console.log(_treeData)
			$.each(_treeData,(id,el)=>{
				//console.log(el.position);
				let target=originalTrees[el.treeNum].clone();
				target.material=target.material.concat();
				target.castShadow=true;
				//let target=new THREE.Mesh(originalTrees[el.treeNum].geometry,originalTrees[el.treeNum].material)
				let wrapObj=new THREE.Object3D();
				wrapObj.add(target);

				let guidV=yVector.clone().applyQuaternion(new THREE.Quaternion(el.quaternion.x,el.quaternion.y,el.quaternion.z,el.quaternion.w))
				guidV.multiplyScalar(0.935);

				wrapObj.position.x=el.position.x*0.94;
				wrapObj.position.y=el.position.y*0.94;
				wrapObj.position.z=el.position.z*0.94;

				//wrapObj.position.copy(guidV);
				

				wrapObj.quaternion.x=el.quaternion.x;
				wrapObj.quaternion.y=el.quaternion.y;
				wrapObj.quaternion.z=el.quaternion.z;
				wrapObj.quaternion.w=el.quaternion.w;
				//let wrapObj=new THREE.Object3D();
				
				TreeObj3D.add(wrapObj);


				let PIId=id%PIL;
				//console.log("PIId="+PIId+":"+PI.slice(PIId,PIId+1))
				let PINum=-0.1+0.03*(Number(PI.slice(PIId,PIId+1)));
				//console.log("PINum="+PINum)

				let bre=31;
				let offset=2*(-(bre-1)/2+(id%bre))/(bre-1);
				let bre2=17;
				let offset2=Math.abs(2*(-(bre2-1)/2+(id%bre2))/(bre2-1));

				let HSLColor;
				let haMaterial=target.material[0].clone();
				let mikiMaterial=target.material[1].clone();
				target.material[0]=haMaterial;
				target.material[1]=mikiMaterial;
				mikiMaterial.color.offsetHSL(PINum*0.1,-0.1,-0.1-0.1*offset2);

				offsetHue=(RANDOM_NUMS[id%RANDOM_NUMS.length]);

				if(el.treeNum==4){
					HSLColor=haMateriaColor1.clone();
					HSLColor.offsetHSL(PINum*0.1,-0.1,-0.1-0.1*offset2);


				}else if(el.treeNum==6){
					HSLColor=haMateriaColor2.clone();
					HSLColor.offsetHSL(PINum*0.1,-0.1,-0.15-0.1*offset2);

				}else if(el.treeNum==2){
					HSLColor=haMateriaColor3.clone();
					HSLColor.offsetHSL(0.2*offsetHue,0,-0.0-0.0*offset2);
					haMaterial.emissive=haMateriaColor3;
					haMaterial.emissiveIntensity=0.5;

				}else if(el.treeNum==1){
					HSLColor=new THREE.Color(0.25,0.5,0.35);
					//console.log("PINum="+PINum)
					
					HSLColor.offsetHSL(0.1*offsetHue,0.5,-0.2-0.1*offset2);
					haMaterial.emissive=HSLColor;
					haMaterial.emissiveIntensity=0.25;

				}else{

					HSLColor=haMateriaColor.clone();
					HSLColor.offsetHSL(0.05*offsetHue,0,-0.3-0.25*offset2);
					//haMaterial.emissive=HSLColor.offsetHSL(0,0,-0.12);
					haMaterial.emissiveIntensity=0.5;
					//HSLColor=new THREE.Color(1,0,0);
				}

				/*var RANDOM_NUMS=[];

				for(var n=0;n<100;n++){
					randomNum=Math.floor(Math.random()*100)/100;
					RANDOM_NUMS.push(randomNum)
				}
				console.log(RANDOM_NUMS="["+RANDOM_NUMS+"]");

				randomNum=Math.random()*/

				//haMaterial.emissive=HSLColor;
				//haMaterial.emissiveIntensity=1;
				

				 //HSLColor.offsetHSL(PINum,0.1,-0.3-0.2*offset2);

				

				haMaterial.color.copy(HSLColor);
				//haMaterial.emissiveIntensity=0;

				

				let bump=(id%2==0)?kiMap2:kiMap3;
				haMaterial.bumpMap=bump;
				haMaterial.map=bump;
				haMaterial.bumpScale=2;
				//haMaterial.emissive=new THREE.Color(0xffffff);
				//haMaterial.emissiveIntensity=0.105;
				//target.material[1]=haMaterial;

				let origenalBox=originalTrees[el.treeNum].geometry.boundingBox;
				//condolr.log()
				let targetMin=new THREE.Vector3();
				let targetMax=new THREE.Vector3();
				//console.log(el)
				targetMin.x=el.box3.min.x;
				targetMin.y=el.box3.min.y;
				targetMin.z=el.box3.min.z;
				targetMax.x=el.box3.max.x;
				targetMax.y=el.box3.max.y;
				targetMax.z=el.box3.max.z;

				let targetBox=new THREE.Box3(targetMin,targetMax);
				let origenalBoxSize=origenalBox.getSize(new THREE.Vector3());
				let targetBoxSize=targetBox.getSize(new THREE.Vector3());
				let scale=targetBoxSize.divide(origenalBoxSize);
				
				target.scale.copy(scale);
				
				

			})

			for(var n=0;n<TreeObj3D.children.length;n++){
				let keyCount=n%RandomNums.length;
				updateDelays[n]=updateDelay*0.75+updateDelay*0.35*0.05*RandomNums[keyCount];
			}




			
			initEnd();
		}

		let updateCount=0;
		let updateCountMax=300;
		updateCountMax=20;
		//let updateCountOver=
		let updateDelay=500;
		//updateDelay=200;

		let RandomNums=[];
		for(var n=0;n<20;n++){
			RandomNums.push(n);
		}

		RandomNums=utils.makeRandomArray2(RandomNums);
		let updateDelays=[];

		//console.log("RandomNums=");
		//console.log(RandomNums)

		

		//console.log("updateDelays="+updateDelays)

		let scaleRatio=1;

		this.update=()=>{
			updateCount+=1;

			//console.log(updateCount)

			//console.log(delays)
			//console.log(TreeObj3D.children.length)
			
			if(updateCount<=updateCountMax+updateDelay*2){
				$.each(TreeObj3D.children,(id,el)=>{
					//let delay=delays[id]*30;
					let delay=updateDelays[id];
					
					let delayCount=(updateCount<delay)?0:updateCount-delay;
					let delayCountMax=updateDelays[TreeObj3D.children.length-id-1]*2;

					delayCountMax=updateCountMax;

					let ratio=(delayCount<=delayCountMax)?delayCount/delayCountMax:1;
					ratio=Math.pow(ratio,0.25);
					if(id<10){
						//console.log("delay="+delay)
						//console.log("ratio="+ratio)
					}
					
					if(ratio>0){
						//el.scale.set(ratio*1.5,Math.pow(ratio,0.5),ratio*1.5);
						el.scale.set(ratio*1.5*scaleRatio,Math.pow(ratio,0.5)*scaleRatio,ratio*1.5*scaleRatio);
						el.visible=true;
					}else{
						el.scale.set(0.01,0.01,0.01);
						el.visible=false;
					}
				})
			}
		}
		

		function analizeLoadFbx(_obj){
				let obj3D=new THREE.Object3D();


				let name=_obj.name;

				//console.log("name="+name)
				


				let attrPosition=_obj.geometry.attributes.position;
				let attrNormal=_obj.geometry.attributes.normal;
				if(_obj.geometry.attributes.uv2){
					let attrUv=_obj.geometry.attributes.uv2;
				}else{
					let attrUv=_obj.geometry.attributes.uv;
				}
				let group=_obj.geometry.groups;


				let positionArray=attrPosition.array;
				let positionCount=attrPosition.count;
				let normalArray=attrNormal.array;
				if(attrUv){
					let uvArray=attrUv.array;
				}


				let materials=_obj.material;//配列

				//trace("group.length="+group.length)


				for(var l=0;l<group.length;l++){
					let start=group[l].start;
					let count=group[l].count;
					let materialIndex=group[l].materialIndex;

					//console.log(l+":start="+start+",count="+count+",materialIndex="+materialIndex)

					let positions=new Float32Array(count*3);
					let normals=new Float32Array(count*3);
					if(uvArray){
						let uvs=new Float32Array(count*2);
					}

					for(var n=start;n<count+start;n++){
						let countZero=(n-start)*3;
						let countNow=n*3;
						let uvZero=(n-start)*2;
						let uvNow=n*2;
						if(l==1){
							//console.log(countZero+"->"+countNow)
						}
						positions[countZero]=positionArray[countNow];
						positions[countZero+1]=positionArray[countNow+1];
						positions[countZero+2]=positionArray[countNow+2];
						normals[countZero]=normalArray[countNow];
						normals[countZero+1]=normalArray[countNow+1];
						normals[countZero+2]=normalArray[countNow+2];
						if(uvArray){
							uvs[uvZero]=uvArray[uvNow];
							uvs[uvZero+1]=uvArray[uvNow+1];
						}
					}
					let newGeometry=new THREE.BufferGeometry();
					newGeometry.addAttribute('position',new THREE.BufferAttribute(positions,3));
					newGeometry.addAttribute('normal',new THREE.BufferAttribute(normals,3));


					if(uvs){
						newGeometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));
					}

					//console.log("materials=")
					//console.log(materials)
					let material=((materials.length)?materials[materialIndex]:materials;

					//console.log("material=")
					//console.log(material)

					material.flatShading=true;
					material.skinning=false;
					material.side=THREE.DoubleSide;
					//material.wireframe=true;

					if(material.name.match(/_ha/)){
						//material.color=new THREE.Color(1,0,0); 
						//material.map="";
						//material.emissiveIntensity=0.5;
						//material.side=THREE.DoubleSide;
						
					}



					let mesh=new THREE.Mesh(newGeometry,material);
					//mesh.name=name;
					//obj3D.name=name;
					//mesh.scale.set(0.3,0.3,0.3);

				
					obj3D.add(mesh);

				
				}

				return obj3D;
			}

			function getOneGeometryMesh(meshs){
				let groups=[];
				let materials=[];

				//console.log(meshs)

				//materialはmikiとhaの2種類だけの前提

				let geometryCount=0;

				for(var n=0;n<meshs.children.length;n++){
					let geometry=meshs.children[n].geometry;
					let positions=geometry.attributes.position;
					geometryCount+=positions.count;
					let material=meshs.children[n].material;
					if(materials.indexOf(material)==-1){
						//console.log("material="+material.name)
						materials.push(material);
					}
				}
				//console.log("geometryCount="+geometryCount);
				//console.log(materials);

				let newPositions=new Float32Array(geometryCount*3);
				let newUvs=new Float32Array(geometryCount*2);

				geometryCount=0;
				let newGeometry=new THREE.BufferGeometry();

				

				for(var n=0;n<meshs.children.length;n++){
					geometry=meshs.children[n].geometry;
					positions=geometry.attributes.position;
					let uvs=geometry.attributes.uv;
					newGeometry.addGroup(geometryCount,positions.count,materials.indexOf(meshs.children[n].material));
					for(var m=0;m<positions.count;m++){
						newPositions[geometryCount*3]=positions.array[m*3];
						newPositions[geometryCount*3+1]=positions.array[m*3+1];
						newPositions[geometryCount*3+2]=positions.array[m*3+2];
						newUvs[geometryCount*2]=uvs.array[m*2];
						newUvs[geometryCount*2+1]=uvs.array[m*2+1];

						geometryCount+=1;
					}

				}


				
				newGeometry.addAttribute('position',new THREE.BufferAttribute(newPositions,3));
				newGeometry.addAttribute('uv',new THREE.BufferAttribute(newUvs,2));



				let newMesh=new THREE.Mesh(newGeometry,materials);
				newMesh.name=Number(materials[0].name.match(/\d+/)[0])-1;

				return newMesh;
			};


	}
}